<template>
  <div :style="{backgroundImage: 'url(' + poll.bannerUrl + ')'}" id="pollQr">
    <PollLanguage @changeLanguagePoll="changeLanguagePoll" :languagePoll="languagePoll" v-if="poll.languageAvailable && poll.languageAvailable === 1" />
    <div class="header_poll" v-if="!msg">
      <div :style="{backgroundImage: 'url(' + poll.logoUrl + ')'}" class="poll_logo"></div>
    </div>
    <div class="cont_curves" v-if="!msg">
      <div class="curve_one"></div>
      <div class="curve_two"></div>
    </div>
    <div class="content_poll" v-if="!msg">
      <div class="cont_title">
        <span class="title_poll ffr fw500" v-html="languagePoll === 'es' ? poll.title : poll.titleEnglish"></span>
      </div>
      <div class="columns">
        <div class="column is-12 main_inputs">
          <form @submit.prevent>
            <b-field :message="errors.first('name')" :type="{'is-danger': errors.has('name')}" class="name-input-container">
              <b-input
                :class="{'error_answer' : errors.has('name')}"
                :placeholder="rules.name == '' ? `${$t('pollQrView.name')}` : `${$t('pollQrView.name')}*`"
                autocomplete="off"
                class="inputs_user name ffr fw300"
                id="name"
                name="name"
                type="text"
                v-model="userForm.name"
                v-validate="rules.name"
              />
            </b-field>
            <b-field
              :message="errors.first('cedula')"
              :type="{'is-danger': errors.has('cedula')}"
              class="type-document-input-container"
            >
              <div class="type-document-poll" @click="documentTypeOptions = !documentTypeOptions" @mouseleave="documentTypeOptions = false">
                <div class="type-document-poll-container">
                  <div class="type-document-poll-container__preview" :class="{ 'type-document-poll-container__preview-placeholder' : userForm.documentType === ''}">
                    <div>{{userForm.documentType}}</div>
                    <span class="material-icons">expand_more</span>
                  </div>
                  <div class="type-document-poll-container__divisor"></div>
                </div>
                <div class="type-document-poll-container__list" v-if="documentTypeOptions">
                  <div>
                    <div v-for="(documentType, index) in listdocumentTypes" :key="index" @click="changeTypeDocument(documentType)">{{documentType}}</div>
                  </div>
                </div>
              </div>
              <b-input
                :class="{'error_answer' : errors.has('cedula')}"
                :placeholder="rules.cedula == '' ? `${$t('pollQrView.identification')}` :`${$t('pollQrView.identification')}*`"
                autocomplete="off"
                class="inputs_user document ffr fw300"
                id="cedula"
                name="cedula"
                ref="cedula"
                type="text"
                v-model="userForm.cedula"
                v-validate="rules.cedula"
              />
            </b-field>
            <b-field :message="errors.first('phone')" :type="{'is-danger': errors.has('phone')}" class="phone-input-container">
              <b-field
                :message="errors.first('indicative')"
                :type="{'is-danger': errors.has('indicative')}"
              >
                <div class="EtCi">
                  <InputTelefono @value="inputNumberChange" :required="rules.phone === 'required' ? true : false" :newPhoneIcon="true" class="NdHv" @getValidationsPhone="getValidationsPhone" :getValidationPhone="true"></InputTelefono>
                </div>
                <p class="help is-danger help-aux-poll-validations" v-if="rules.phone === 'required' && userForm.phone === '' && phoneRequiredAux">{{$t('pollQrView.validations.phoneRequired')}}</p>
              </b-field>
            </b-field>
            <b-field :message="errors.first('email')" :type="{'is-danger': errors.has('email')}">
              <b-input
                :class="{'error_answer' : errors.has('email')}"
                :placeholder="validateRequired(rules.email) ? `${$t('pollQrView.email')}` : `${$t('pollQrView.email')}*`"
                autocomplete="off"
                class="inputs_user email ffr fw300"
                id="email"
                name="email"
                type="email"
                v-model="userForm.email"
                v-validate="rules.email"
              />
            </b-field>
            <div class="cont_check_terms" id="cont_check" tabindex="-1">
              <label class="container">
                <input
                  :checked="terms"
                  @click="validateTerms()"
                  name="terms"
                  type="checkbox"
                  v-model="terms"
                  v-validate="'required'"
                />
                <span class="checkmark"></span>
                <span class="texts_check ffr fw400">{{ $t('pollQrView.text1') }}</span>
              </label>
              <p class="asterix ffr fw300" v-if="this.errorTerms">{{ $t('pollQrView.text2') }}</p>
            </div>
          </form>
        </div>
      </div>
      <div class="cont_questions">
        <div class="cont_reserve">
          <span class="question ffr fw500">{{ $t('pollQrView.text3') }}</span>
          <div class="cont_check">
            <span class="radioText ffr fw400">{{ $t('pollQrView.text4') }}&nbsp;&nbsp;</span>
            <b-radio
              :native-value="true"
              class="radioOption"
              name="idReservation"
              size="is-small"
              type="is-black"
              v-model="ifReservation"
            ></b-radio>
          </div>
          <div class="cont_check">
            <span class="radioText ffr fw400">{{ $t('pollQrView.text5') }}&nbsp;&nbsp;</span>
            <b-radio
              :native-value="false"
              class="radioOption"
              name="idReservation"
              size="is-small"
              type="is-black"
              v-model="ifReservation"
            ></b-radio>
          </div>
        </div>
        <div class="cont_description">
          <span class="ffr" v-html="languagePoll === 'es' ? poll.description : poll.descriptionEnglish"></span>
        </div>
        <div v-if="calification.length > 0">
          <div class="columns is-multiline mx-0 main_stars_poll">
            <div
              :key="preg.index"
              class="column is-4 is-4-mobile stars_poll"
              v-for="(preg) in starQuestions"
            >
              <div :id="'qp' + preg.index" :tabindex="preg.index" class="cont_stars">
                <span
                  :class="{'error_answer' : fails[preg.index] && calification[preg.index]['value'] == 0}"
                  class="question ffr fw500"
                >
                  {{
                  languagePoll === 'es' ? preg.question : preg.questionEnglish
                  }}*
                </span>
                <Stars :questions="preg" v-model="calification[preg.index]['value']"></Stars>
              </div>
            </div>
          </div>
          <div class="columns is-multiline mx-0 main_questions">
            <div
              :class="{'first' : !(ind % 2)}"
              :id="'qp' + preg.index"
              :key="preg.index"
              :tabindex="preg.index"
              class="column is-6 questions_poll"
              v-for="(preg, ind) in otherQuestions"
            >
              <div v-if="preg.type === 'checkbox'">
                <span
                  :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                  class="question ffr fw500"
                >{{ preg.question }}*</span>
                <Check
                  :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                  :questions="preg"
                  v-model="calification[preg.index]['value']"
                ></Check>
              </div>
              <MultiRadio
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'multiRadio'"
                v-model="calification[preg.index]['value']"
              ></MultiRadio>
              <OpenQuestion
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'openQuestion'"
                v-model="calification[preg.index]['value']"
              ></OpenQuestion>
              <RadioQuestion
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'radioQuestion'"
                v-model="calification[preg.index]['value']"
              ></RadioQuestion>
              <HourQuestionVue
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'hourQuestion'"
                v-model="calification[preg.index]['value']"
              ></HourQuestionVue>
              <CalendarQuestionVue
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'calendarQuestion'"
                v-model="calification[preg.index]['value']"
              ></CalendarQuestionVue>
              <OpenQuestionInput
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'openQuestionInput'"
                v-model="calification[preg.index]['value']"
              ></OpenQuestionInput>
              <RadioQuestionButtonVue
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'radioButtonQuestion'"
                v-model="calification[preg.index]['value']"
              ></RadioQuestionButtonVue>
              <YesOrNoQuestionVue
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'yesOrNoQuestion'"
                v-model="calification[preg.index]['value']"
              ></YesOrNoQuestionVue>
            </div>
          </div>
        </div>
        <div class="cont_comments">
          <textarea
            :placeholder="languagePoll === 'es' ? poll.comments : poll.commentsEnglish"
            class="textarea_comments ffr fw400"
            style="min-height: 100px; max-height: 100px; height: 100px;"
            v-model="userForm.comments"
          ></textarea>
        </div>
        <div class="cont_error" v-if="error != ''">
          <span class="f12">
            <i class="fa fa-exclamation-triangle"></i>
            &nbsp;&nbsp;{{ error }}
          </span>
        </div>
        <div class="cont_button">
          <button
            :disabled="loading"
            @click="sendAnswer()"
            class="btn_send ffr fw500"
            v-if="!loading"
          >{{ $t('pollQrView.text6') }}</button>
          <button class="btn_send ffr fw500" v-if="loading">{{ $t('pollQrView.text7') }}</button>
        </div>
        <div class="cont_query">
          <span class="ffr fw400">
            {{ $t('pollQrView.text8') }}
            <a
              :href="'tel:' + vendor.phone"
              class="ffr fw500"
            >{{ vendor.phone }}</a>
          </span>
          <span class="ffr fw400" v-if="vendor.email != null">
            {{ $t('pollQrView.text9') }}
            <a
              :href="'mailto:' + vendor.email"
              class="ffr fw500"
            >{{ vendor.email }}</a>
          </span>
        </div>
      </div>
    </div>
    <div
      class="content_poll fallo_msg center-poll-trip change-size"
      style="flex-direction: column;"
      v-if="msg"
    >
      <div class="cont_msg">
        <div class="header_poll" style="padding-top: 5px; background: unset;">
          <div :style="{backgroundImage: 'url(' + poll.logoUrl + ')'}" class="poll_logo"></div>
        </div>
        <span class="text_ty ffr fw500">{{ $t('pollQrView.text10') }}</span>
        <span class="text_response ffr fw400">{{ $t('pollQrView.text11') }}</span>
        <span class="text_ty ffssp fw400" v-if="showTripAd">{{ $t('pollView.text7') }}</span>
        <div class="img_external_father" v-if="showTripAd">
          <div
            @click="gotToClick('trip')"
            class="img_external"
            style="margin-right: 10px;"
            v-if="params.tripAdvisorURL"
          >
            <img
              alt="precompro.com"
              class="img_external_item_trip"
              src="@/assets/images/icons/polls/trip.svg"
            />
          </div>
          <div @click="gotToClick('google')" class="img_external" v-if="params.googleQualifyURL">
            <img
              alt="precompro.com"
              class="img_external_item_gogle"
              src="@/assets/images/icons/polls/gogle.svg"
            />
          </div>
        </div>
      </div>
      <div class="showInfoPoll">
        <span class="showInfoPoll-Item">
          Si tienes alguna consulta al respecto, por favor comunicarse al
          {{ vendor.phone }} ó escríbenos a {{ vendor.email }}
        </span>
      </div>
    </div>
    <div class="footer_poll">
      <img
        alt="built by precompro.com"
        class="img_footer"
        src="@/assets/images/icons/polls/logo_precompro.svg"
      />
    </div>
  </div>
</template>
<script>
import InputTelefono from '@/components/Select/inputs/inputSelectLogin.vue';
import globalTypes from '@/store/types/global';
import whiteLabelTypes from '@/store/types/whiteLabel';
import { mapGetters } from 'vuex';
import Stars from '@/components/pollQuestions/stars';
import Check from '@/components/pollQuestions/checkbox';
import MultiRadio from '@/components/pollQuestions/multiRadio';
import OpenQuestion from '@/components/pollQuestions/openQuestion';
import RadioQuestion from '@/components/pollQuestions/radioQuestion';
import _ from 'lodash';
import RadioQuestionButtonVue from '@/components/pollQuestions/radioQuestionButton.vue';
import OpenQuestionInput from '@/components/pollQuestions/openQuestionInput.vue';
import CalendarQuestionVue from '@/components/pollQuestions/calendarQuestion.vue';
import HourQuestionVue from '@/components/pollQuestions/hourQuestion.vue';
import YesOrNoQuestionVue from '../components/pollQuestions/yesOrNoQuestion.vue';
import PollLanguage from '@/components/pollQuestions/pollLanguage.vue';

export default {
  components: {
    Stars,
    YesOrNoQuestionVue,
    Check,
    MultiRadio,
    OpenQuestion,
    RadioQuestion,
    RadioQuestionButtonVue,
    OpenQuestionInput,
    CalendarQuestionVue,
    HourQuestionVue,
    InputTelefono,
    PollLanguage
  },
  data () {
    return {
      calification: [],
      loading: false,
      msg: false,
      error: '',
      fallo: '',
      date: null,
      ifReservation: true,
      terms: false,
      errorTerms: false,
      initBandera: '',
      userForm: {
        name: null,
        email: null,
        phone: '',
        cedula: null,
        hasReservation: null,
        comments: null,
        documentType: 'C.C.'
      },
      rules: {
        name: '',
        email: 'email',
        phone: '',
        cedula: '',
        indicative: 'regex:^\\+\\d+$'
      },
      starQuestions: [],
      otherQuestions: [],
      fails: [],
      firstFail: null,
      totalStars: 0,
      starsCount: 0,
      ponderadoStars: 0,
      showTripAd: false,
      languagePoll: '',
      listdocumentTypes: [
        'C.C.',
        'C.E.',
        'NIT',
        'PA',
        'Otro'
      ],
      documentType: 'C.C.',
      documentTypeOptions: false,
      phoneRequiredAux: false,
      phoneValidationFromComponent: false
    };
  },
  computed: {
    ...mapGetters({
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults]
    })
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    let subdomain = this.subDomain();
    const vendorUrl = subdomain;
    this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
    this.$store.dispatch({ type: 'whiteLabel:vendorId', data: { url: vendorUrl } })
      .then(response => {
        if (response.data.vendorId) {
          this.$store
            .dispatch({
              type: 'whiteLabel:vendor',
              data: { vendorId: response.data.vendorId }
            })
            .then(data => {
              document.title = data.data.vendor.displayName
                ? data.data.vendor.displayName + ' - '
                : '';
              document.querySelector("link[rel*='icon']").href = (data.data.vendor.photoURL !== null) ? data.data.vendor.photoURL : data.data.vendor.thumbnailURL;
              this.getRulesFields(data.data.params);
            });
        }
      });
    this.$store.dispatch({ type: 'poll:pollVendor', data: { vendor: this.vendor.id } })
      .then(response => {
        if (response.status === 200) {
          if (Object.keys(this.poll).length > 0 && this.calification.length === 0) {
            let qualify = [];
            _.forEach(this.poll.preguntas, function (item) {
              let i = {
                type: item.question,
                sub_option: item.sub_option,
                id: item.id,
                value: 0
              };
              qualify.push(i);
            });
            this.calification = qualify;
          }
        } else {
          if (response.status === 404) {
            this.date = response.data.date;
            this.fallo = response.data.message;
          } else {
            this.fallo = 'Información de encuesta no encontrada!';
          }
        }
      })
      .catch(error => {
        if (error.status === 404) {
          this.fallo = error.data.message;
        } else {
          this.fallo = 'Información de encuesta no encontrada!';
        }
      });
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  },
  methods: {
    gotToClick (provider) {
      switch (provider) {
        case 'google':
          window.open(this.params.googleQualifyURL);
          break;
        case 'trip':
          window.open(this.params.tripAdvisorURL);
          break;
      }
    },
    inputNumberChange (value) {
      this.userForm.bandera = value.countrieInfo.bandera;
      this.userForm.indicativo = value.countrieInfo.indicativo;
      this.userForm.country = value.countrieInfo.pais;
      this.userForm.phone = value.inputValue;
    },
    getRulesFields (params) {
      let newRules = { ...this.rules };
      if (params.isRequiredName) {
        newRules.name = 'required';
      }
      if (params.isRequiredEmail) {
        newRules.email = 'required|email';
      }
      if (params.isRequiredPhone) {
        newRules.phone = 'required';
      }
      if (params.isRequiredCedula) {
        newRules.cedula = 'required|regex:^[a-zA-Z0-9]*';
      }
      if (params.isRequiredIndicative) {
        newRules.indicative = 'required|regex:^[a-zA-Z0-9]*';
      }
      this.rules = newRules;
    },
    validateTerms () {
      this.errorTerms = false;
    },
    sendAnswer () {
      this.totalStars = 0;
      let error = 0;
      this.firstFail = null;
      let inThis = this;
      _.map(this.calification, (calification, i) => {
        if (calification.typeQuestion === 'stars') {
          inThis.totalStars += parseInt(calification.value);
        }
        if (calification.value === 0 || calification.value === '' || calification.value === null) {
          error = 1;
          this.fails[i] = true;
          if (this.firstFail === null) {
            this.firstFail = i;
          }
        } else {
          this.fails[i] = false;
        }
      });
      const copyCalification = [...this.calification];
      this.calification = copyCalification;
      if (this.firstFail !== null) {
        document.getElementById('qp' + this.firstFail).focus();
      }
      if (error) {
        this.error = 'Todos los campos con (*) son obligatorios.';
      } else {
        this.loading = true;
        this.userForm.hasReservation = this.ifReservation;
        const newCalifications = this.calification.map((calification) => {
          if (typeof calification.value === 'object') {
            calification.extraValue = calification.value.extraValue;
            calification.value = calification.value.option;
          }
          return calification;
        });
        let data = {
          vendor: this.vendor.id,
          pollUser: this.userForm,
          answers: newCalifications
        };
        this.$validator.validateAll()
          .then(result => {
            if (this.terms) {
              this.validationPhone();
              if (result) {
                this.sendDataPoll(data);
              } else {
                this.loading = false;
                const idInput = this.$validator._base.errors.items[0].field;
                document.getElementById(idInput).focus();
              }
            } else {
              this.validationPhone();
              this.loading = false;
              this.errorTerms = true;
              document.getElementById('cont_check').focus();
            }
          });
      }
    },
    sendDataPoll (data) {
      this.loading = false;
      if (this.rules.phone === '') {
        this.phoneValidationFromComponent = false;
      }
      if (!this.phoneValidationFromComponent && !this.phoneRequiredAux) {
        this.loading = true;
        if (!this.params.isRequiredCedula) {
          this.userForm.documentType = null;
        }
        this.$store
          .dispatch({
            type: 'poll:sendAnswerUserPoll',
            data
          })
          .then(response => {
            this.userForm.documentType = 'C.C.';
            if (response.status === 200) {
              this.ponderadoStars = this.totalStars / this.starsCount;
              if (this.ponderadoStars >= 4 && this.params.activeTripAdvisor) {
                this.showTripAd = true;
              }
              this.msg = true;
              this.loading = false;
            } else {
              this.loading = false;
              this.error = 'No se pudo guardar sus respuestas, intentelo de nuevo por favor.';
            }
          }).catch(() => {
            this.userForm.documentType = 'C.C.';
          });
      }
    },
    validateRequired (rule) {
      const r = rule.split('required');
      if (r.length > 1) {
        return false;
      }
      return true;
    },
    changeLanguagePoll () {
      this.languagePoll = this.languagePoll === 'es' ? 'en' : 'es';
    },
    changeTypeDocument (type) {
      this.userForm.documentType = type;
    },
    validationPhone () {
      if (this.params.isRequiredPhone && this.rules.phone === 'required' && this.userForm.phone === '') {
        this.phoneRequiredAux = true;
      } else {
        this.phoneRequiredAux = false;
      }
    },
    getValidationsPhone (value) {
      this.phoneValidationFromComponent = value;
    }
  },
  watch: {
    // revisa si escribe en el input de indicativo y muestra lo escrito por consola
    'userForm.indicative': function (val, oldVal, event) {
      const regex = /^\+\d{0,3}$/gm;
      if (val.length > 0) {
        if (val[0] !== '+') {
          this.userForm.indicative = '+' + val.slice(0);
        } else if (!regex.test(val)) {
          this.userForm.indicative = oldVal;
        } else {
          this.userForm.indicative = val.slice(0, 4);
        }
      } else {
        this.userForm.indicative = '+';
      }
    },
    'userForm.phone': function (val, oldVal, event) {
      this.validationPhone();
    },
    poll () {
      if (this.poll.languageAvailable !== undefined && this.poll.languageAvailable === 0) {
        this.languagePoll = 'es';
      } else {
        this.languagePoll = window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'es';
      }
      let inThis = this;
      if (Object.keys(this.poll).length > 0 && this.calification.length === 0) {
        let data = [];
        _.forEach(this.poll.preguntas, function (item) {
          let i = {
            type: item.question,
            id: item.id,
            typeQuestion: item.type,
            value: 0
          };
          data.push(i);
          if (item.type === 'stars') {
            inThis.starsCount++;
          }
        });
        this.poll.preguntas.forEach((element, i) => {
          element.index = i;
          if (element.type === 'stars') {
            this.starQuestions.push(element);
          } else {
            this.otherQuestions.push(element);
          }
        });
        this.calification = data;
      }
    },
    languagePoll (value) {
      this.$i18n.locale = value;
      this.$moment.locale(value);
      this.$validator.locale = value;
      window.localStorage.setItem('lang', value);
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Polls/poll.scss";
@import "@/assets/styles/Polls/pollQr.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
</style>
